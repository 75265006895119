import React from "react";
import { StyledInput } from './StyledInput';

export const TextInput = React.forwardRef(
    (props, ref: any) => {
    
    return (
        <StyledInput 
            ref={ref} 
            type='text' 
            {...props} />
    )
})