import React from "react";
import { Provider } from "react-redux";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ProtectedRouteWithStore } from "./components/shared/ProtectedRoute";
import { VerificationWithStore } from "./pages/Verification";
import { store } from "./shared/redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/main.scss";
import { RegistrationWithStore } from "./pages/Registration";
import { ConfirmationWithStore } from "./pages/Confirmation";
import { ConditionsWithStore } from "./pages/Conditions";
import { LoadingOverlayWithStore } from "./components/shared/LoadingOverlay";
import { ScrollToTop } from "./components/shared/ScrollToTop";
import { UrlResolverWithStore } from "./components/UrlResolver";

export const App = () => {
    return (
        <Provider store={store}>
            <div className="App">
                <Router>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/verification" />
                        </Route>
                        <Route exact path="/verification">
                            <VerificationWithStore />
                        </Route>
                        <ProtectedRouteWithStore
                            {...{
                                exact: true,
                                path: "/conditions",
                                component: ConditionsWithStore
                            }}
                        />
                        <ProtectedRouteWithStore
                            {...{
                                exact: true,
                                path: "/registration",
                                component: RegistrationWithStore
                            }}
                        />
                        <ProtectedRouteWithStore
                            {...{
                                exact: true,
                                path: "/confirmation",
                                component: ConfirmationWithStore
                            }}
                        />
                        <Route exact path="*">
                            <Redirect to="/verification" />
                        </Route>
                    </Switch>
                    <UrlResolverWithStore />
                </Router>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                className="fc-toast-container"
                toastClassName="fc-toast"
                bodyClassName="fc-toast-body"
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
            />
            <LoadingOverlayWithStore />
        </Provider>
    );
};
