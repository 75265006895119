export enum Color {
    Black = "var(--black)",
    White = "var(--white)",
    Primary = "var(--primary)",
    Secondary = "var(--secondary)",
    Grey = "var(--grey)",
    LightGrey = "var(--light-grey)",
    LighterGrey = "var(--lighter-grey)",
    Purple = "var(--purple)",
    Blue = "var(--blue)",
    Orange = "var(--orange)",
    Yellow = "var(--yellow)",
    Red = "var(--red)",
    DarkRed = "var(--dark-red)",
    Green = "var(--green)"
}
