import styled from 'styled-components';

export const StyledInput = styled.input`
    background-color: var(--white);
    padding: var(--size-08) var(--size-16);
    width: 100%;
    color: var(--secondary);
    border: 1px solid var(--secondary);

    ::placeholder {
        color: var(--light-grey);
    }

    &:focus {
        outline: none;
        border: 1px solid var(--primary);
    }
`;