import React from "react";
import { connect } from "react-redux";
import { Spinner } from ".";
import { IState } from "../../../shared/interfaces/state.interface";
import { selectLoadingStatus } from "../../../shared/redux/app.selectors";
import { Overlay, OverlayBackground } from "../Overlay";

interface ILoadingOverlayProps {
    isLoading: boolean;
}

export const LoadingOverlay = (props: ILoadingOverlayProps) => {
    const { isLoading } = props;

    return isLoading ? (
        <Overlay backgroundColor={OverlayBackground.Light}>
            <Spinner />
        </Overlay>
    ) : null;
};

const mapStateToProps = (state: IState) => {
    return {
        isLoading: selectLoadingStatus(state)
    };
};

export const LoadingOverlayWithStore = connect(mapStateToProps, {})(LoadingOverlay);
