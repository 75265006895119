import { Dispatch } from "redux";
import {
    setContractData,
    setLoadingStatusAction,
    setSuccessFulRegistration,
    setVerificationCodeAction
} from "./app.actions";
import * as httpService from "../services/http.service";
import { ApiEndpoint } from "../enums/api-endpoint.enum";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { IRegistrationData } from "../interfaces/registration-data.interface";

export function verify(code: string) {
    return function dispatch(dispatch: Dispatch) {
        dispatch(setLoadingStatusAction(true));

        try {
            httpService
                .performGetRequest(ApiEndpoint.Verification, { params: { code } })
                .then(({ data: { valid, code, used } }: AxiosResponse) => {
                    if (!valid) {
                        toast("Der von Ihnen angegebene Code ist nicht valide.", {
                            className: "fc-toast error"
                        });
                        dispatch(setVerificationCodeAction(""));
                        return;
                    }

                    if (used) {
                        toast(
                            "Der von Ihnen verwendete Zugagnscode wurde bereits für eine Registrierung verwendet. Sie können sich nicht erneut mit diesem Code registrieren.",
                            {
                                className: "fc-toast info"
                            }
                        );
                        dispatch(setVerificationCodeAction(""));
                        return;
                    } else {
                        toast(
                            `Der von Ihnen eingegebene Code: "${code}" wird nun für die Registrierung verwendet.`,
                            {
                                className: "fc-toast success"
                            }
                        );
                    }

                    dispatch(setVerificationCodeAction(code));
                })
                .catch((error) => handleError(error.response))
                .finally(() => dispatch(setLoadingStatusAction(false)));
        } catch (error) {
            console.error(error);
        }
    };
}

export function getContractDetails(code: string) {
    return function dispatch(dispatch: Dispatch) {
        dispatch(setLoadingStatusAction(true));

        try {
            httpService
                .performGetRequest(ApiEndpoint.ContractDetails, {
                    headers: { Authorization: `Bearer ${code}` }
                })
                .then(({ data }: AxiosResponse) => {
                    dispatch(setContractData(data));
                })
                .catch((error) => handleError(error.response))
                .finally(() => dispatch(setLoadingStatusAction(false)));
        } catch (error) {
            console.error(error);
        }
    };
}

export function register(data: IRegistrationData, code: string, referral: string) {
    return async function dispatch(dispatch: Dispatch) {
        dispatch(setLoadingStatusAction(true));

        try {
            httpService
                .performPostRequest(
                    `${ApiEndpoint.Registration}`,
                    { data, referral },
                    {
                        headers: { Authorization: `Bearer ${code}` }
                    }
                )
                .then(() => {
                    toast("Vielen Dank. Ihre Registrierung war erfolgreich.", {
                        className: "fc-toast success"
                    });
                    dispatch(setSuccessFulRegistration(true, data));
                })
                .catch((error) => {
                    handleError(error.response);
                    dispatch(setSuccessFulRegistration(false, data));
                })
                .finally(() => dispatch(setLoadingStatusAction(false)));
        } catch (error) {
            console.error(error.response);
        }
    };
}

export function handleError(response: AxiosResponse) {
    const errorMessage = response?.data?.error?.message;
    const toastMessage = !!errorMessage
        ? errorMessage
        : "Ein Fehler ist aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";

    toast(toastMessage, {
        className: "fc-toast error"
    });
}
