import { IRegistrationData } from "../interfaces/registration-data.interface";
import { IState } from "../interfaces/state.interface";
import { createSelector } from "reselect";

export const selectVerificationCode = (state: IState): string => state?.verificationCode || "";

export const selectLoadingStatus = (state: IState): boolean => state?.isLoading || false;

export const selectContractData = (state: IState): any => state?.contractData || {};

export const selectSuccessFulRegistration = (state: IState): boolean | undefined =>
    state?.successFulRegistration;

export const selectIsUsedCode = (state: IState): boolean => state?.isUsedCode || false;

export const selectRegistrationData = (state: IState): IRegistrationData =>
    state?.registrationData || {
        acceptedTermsAndPrivacyPolicy: false,
        email: "",
        password: "",
        company: {
            name: "",
            street: "",
            postcode: "",
            city: "",
            phone: ""
        },
        contactPerson: {
            gender: "",
            firstname: "",
            lastname: ""
        },
        billing: {
            accountOwner: "",
            iban: "",
            bic: ""
        }
    };

export const selectReferral = (state: IState): string => state.referral || "";

export const selectRegistrationDataEmail = createSelector(
    [selectRegistrationData],
    (registrationData: IRegistrationData): string => {
        return registrationData.email;
    }
);

export const selectContractFeeMonthly = createSelector(
    [selectContractData],
    (contractData: any): string => {
        const formatter = Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" });
        const value = contractData?.contract?.fees?.monthly || 0;

        return formatter.format(value);
    }
);

export const selectContractFeeSms = createSelector(
    [selectContractData],
    (contractData: any): string => {
        const formatter = Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" });
        const value = contractData?.contract?.fees?.sms || 0;

        return formatter.format(value);
    }
);

export const selectContractInclusiveSms = createSelector(
    [selectContractData],
    (contractData: any): number => {
        return contractData?.contract?.inclusive?.sms || 0;
    }
);
