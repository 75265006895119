import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { setReferralAction } from "../shared/redux/app.actions";

interface IUrlResolverProps {
    setReferral: (referral: string) => void;
}

export const UrlResolver = (props: IUrlResolverProps) => {
    const { setReferral } = props;
    const locationSearch = useLocation().search;

    useEffect(() => {
        const searchParams = new URLSearchParams(locationSearch);
        const referral = searchParams.get("referral");

        if (!!referral) {
            setReferral(referral);
        }
    }, [setReferral, locationSearch]);

    return <></>;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setReferral: (referral: string) => dispatch(setReferralAction(referral))
    };
};

export const UrlResolverWithStore = connect(null, mapDispatchToProps)(UrlResolver);
