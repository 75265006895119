import React from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";

const FormElementWrapper = styled.div`
    margin-bottom: var(--size-08);

    label {
        display: block;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: var(--size-08);
    }
`;

const RadioWrapper = styled.div`
    display: flex;
    align-items: center;

    label {
        &:not(:last-of-type) {
            margin-right: 16px;
        }
    }
`;

const Error = styled.p`
    color: var(--red);
    padding: var(--size-08) 0;
    margin: 0;
    font-size: 10px;
`;

interface IFormElementProps {
    label?: string;
    children?: JSX.Element | JSX.Element[];
    required?: boolean;
    isRadioElement?: boolean;
    error?: FieldError;
    className?: string;
}

export const FormElement = (props: IFormElementProps) => {
    const { label, children, required, isRadioElement, error, className } = props;

    return (
        <FormElementWrapper className={className}>
            {!!label && (
                <label>
                    {label} {required && <abbr>*</abbr>}
                </label>
            )}
            {isRadioElement ? <RadioWrapper>{children}</RadioWrapper> : <>{children}</>}
            {!!error && <Error>{error.message}</Error>}
        </FormElementWrapper>
    );
};
