import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ConditionTeaserWithStore } from "../components/ConditionTeaser/ConditionTeaser";
import { RegistrationFormWithStore } from "../components/Form/RegistrationForm";
import { Footer } from "../components/shared/Footer";
import { Header } from "../components/shared/Header";
import { IState } from "../shared/interfaces/state.interface";
import { getContractDetails } from "../shared/redux/app.api-middleware";
import {
    selectSuccessFulRegistration,
    selectVerificationCode
} from "../shared/redux/app.selectors";

const StyledContainer = styled(Container)`
    max-width: 1400px;
`;

const ColWithSpace = styled(Col)`
    padding: 0 47px;
`;
interface IRegistrationProps {
    verificationCode: string;
    successFulRegistration: boolean | undefined;
    getContractDetails: (code: string) => Promise<void>;
}

export const Registration = (props: IRegistrationProps) => {
    const { verificationCode, successFulRegistration, getContractDetails } = props;
    const history = useHistory();

    useEffect(() => {
        if (!verificationCode) {
            return;
        }

        const fetchData = async () => {
            await getContractDetails(verificationCode);
        };

        fetchData();
    }, [getContractDetails, verificationCode]);

    useEffect(() => {
        if (successFulRegistration) {
            history.push("/confirmation");
        }
    }, [history, successFulRegistration]);

    return (
        <>
            <Header />
            <StyledContainer>
                <Row>
                    <ColWithSpace
                        xs={12}
                        sm={{ span: 10, offset: 1 }}
                        md={{ span: 8, offset: 2 }}
                        lg={{ span: 6, offset: 0 }}
                    >
                        <ConditionTeaserWithStore />
                    </ColWithSpace>
                    <ColWithSpace
                        xs={12}
                        sm={{ span: 10, offset: 1 }}
                        md={{ span: 8, offset: 2 }}
                        lg={{ span: 6, offset: 0 }}
                    >
                        <RegistrationFormWithStore />
                    </ColWithSpace>
                </Row>
            </StyledContainer>
            <Footer />
        </>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        verificationCode: selectVerificationCode(state),
        successFulRegistration: selectSuccessFulRegistration(state)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getContractDetails: (code: string) => dispatch(getContractDetails(code))
    };
};

export const RegistrationWithStore = connect(mapStateToProps, mapDispatchToProps)(Registration);
