import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { DateHelper } from "../../shared/helper/date.helper";
import { IState } from "../../shared/interfaces/state.interface";
import {
    selectContractFeeMonthly,
    selectContractFeeSms,
    selectContractInclusiveSms
} from "../../shared/redux/app.selectors";

const Teaser = styled.div`
    position: relative; // IE Fallback
    margin-bottom: 48px;

    @media screen and (min-width: 992px) {
        position: sticky;
        top: 32px;
    }
`;

const ConditionTitle = styled.h2`
    font-size: 32px;
    color: var(--primary);
    font-weight: 700;
    margin-bottom: 8px;
`;

const ConditionPrice = styled.h3`
    font-size: 64px;
    color: var(--secondary);
    line-height: 1.5;
    font-weight: 700;

    small {
        display: block;
        color: var(--grey);
        font-size: 12px;
    }
`;

const ConditionList = styled.ul`
    list-style-type: none;
    margin-bottom: 48px;

    li {
        padding: 16px 0;
        font-size: 24px;

        &:first-child {
            padding: 32px 0 16px;
        }

        &:last-child {
            padding: 16px 0 0;
        }

        span {
            display: block;

            &.title {
                font-weight: bold;
            }

            &.hint {
                color: var(--grey);
            }
        }
    }
`;

const ConditionHint = styled.p`
    margin-bottom: 0;
    font-size: 16px;
    color: var(--grey);
`;

interface IConditionTeaserProps {
    monthly: string;
    feeSms: string;
    inclusiveSms: number;
}

export const ConditionTeaser = (props: IConditionTeaserProps) => {
    const { monthly, feeSms, inclusiveSms } = props;

    const getCurrentMonthLabel = () => {
        const now = new Date();
        const month = now.getMonth();

        return DateHelper.getEditorableMonth(month);
    };

    return (
        <Teaser>
            <ConditionTitle>Smart Tarif Zurich Service</ConditionTitle>
            <ConditionPrice>
                {monthly}
                <small>netto/Monat zzgl. MwSt.</small>
            </ConditionPrice>
            <ConditionList>
                <li>
                    <span className="title">Flatrate</span>
                    <span>Checkversand per Mail und SMS</span>
                    <span className="hint">({inclusiveSms} SMS / Monat inkl.)*</span>
                </li>
                <li>
                    <span className="title">Mindestlaufzeit</span>
                    <span>12 Monate</span>
                    <span className="hint">Drei Monate Kündigungsfrist zum Ende der Laufzeit</span>
                </li>
                <li>
                    <span className="title">Startsubvention</span>
                    <span>{getCurrentMonthLabel()} kostenlos</span>
                    <span className="hint">
                        Der aktuelle Kalendermonat Ihres Vertrages ist für Sie kostenlos. Die Kosten
                        trägt die Zurich Gruppe Deutschland.
                    </span>
                </li>
            </ConditionList>
            <ConditionHint>
                * Nach Verbrauch der {inclusiveSms} SMS, belaufen sich die Kosten für jede weitere
                SMS auf {feeSms} und werden am Ende des Monats zzgl. zum Grundpreis in Rechnung
                gestellt. Die Kosten für jede weitere SMS werden dem Nutzer nur in Rechnung
                gestellt, wenn und soweit das gemeinsame Monatskontingent aller Agenturen der Zurich
                in dem jeweiligen Monat überschritten worden ist.
            </ConditionHint>
        </Teaser>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        monthly: selectContractFeeMonthly(state),
        feeSms: selectContractFeeSms(state),
        inclusiveSms: selectContractInclusiveSms(state)
    };
};

export const ConditionTeaserWithStore = connect(mapStateToProps, {})(ConditionTeaser);
