import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Footer } from "../components/shared/Footer";
import { Header } from "../components/shared/Header";
import { IState } from "../shared/interfaces/state.interface";
import {
    selectRegistrationDataEmail,
    selectSuccessFulRegistration
} from "../shared/redux/app.selectors";
import SuccessImage from "../assets/images/success.svg";

const ConfirmationTeaser = styled.div`
    position: relative;
    width: 100%;
    background-image: url(${SuccessImage});
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
        font-size: 8vw;
        color: var(--secondary);
        opacity: 0.2;
        padding: 100px 5%;
    }
`;

const StyledRow = styled(Row)`
    margin-top: 48px;

    h2 {
        margin-bottom: 32px;
        font-size: 28px;
    }

    p {
        margin-bottom: 0.9rem;
    }
`;

interface IConfirmationProps {
    successFulRegistration: boolean | undefined;
    registrationDataEmail: string;
}

export const Confirmation = (props: IConfirmationProps) => {
    const { successFulRegistration, registrationDataEmail } = props;
    const history = useHistory();

    useEffect(() => {
        if (!successFulRegistration) {
            history.push("/registration");
        }
    }, [history, successFulRegistration]);

    return (
        <>
            <Header withMargin={false} />
            <ConfirmationTeaser>
                <h1>Ihre Registrierung war erfolgreich!</h1>
            </ConfirmationTeaser>
            <Container>
                <StyledRow>
                    <Col xs={12}>
                        <h2>Account aktivieren</h2>
                        <p>
                            Wir haben eine E-Mail an <strong>{registrationDataEmail}</strong>{" "}
                            geschickt.
                        </p>
                        <p>
                            Bitte klicken Sie auf den Link in der E-Mail, um Ihren Account zu
                            aktivieren.
                        </p>
                    </Col>
                </StyledRow>
            </Container>
            <Footer />
        </>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        successFulRegistration: selectSuccessFulRegistration(state),
        registrationDataEmail: selectRegistrationDataEmail(state)
    };
};

const mapDispatchToProps = () => {
    return {};
};

export const ConfirmationWithStore = connect(mapStateToProps, mapDispatchToProps)(Confirmation);
