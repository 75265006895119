import styled from 'styled-components';
import { ButtonTheme } from './Button';

export const StyledButton = styled.button`
    font-weight: bold;
    padding: var(--size-08) var(--size-16);
    flex-shrink: 0;
    cursor: pointer;
    border: 0;

    &:focus,
    &:active {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
        opacity: .5;
    }

    ${({ theme }) => {
        switch(theme) {
            case ButtonTheme.PrimarySecond:
                return `
                    background-color: var(--secondary);
                    color: var(--primary);

                    &:hover {
                        background-color: var(--primary);
                        color: var(--secondary);
                        cursor: pointer;
                    }
                `;    
            case ButtonTheme.SecondaryFirst:
                return `
                    background-color: transparent;
                    color: var(--secondary);
                    border: 2px solid var(--primary);

                    &:hover {
                        background-color: var(--primary);
                        cursor: pointer;
                    }
                `;
            case ButtonTheme.SecondarySecond:
                return `
                    background-color: transparent;
                    color: var(--secondary);
                    border: 2px solid var(--primary);
                    padding: var(--size-04) var(--size-16);

                    &:hover {
                        background-color: var(--primary);
                        cursor: pointer;
                    }
                `;        
            case ButtonTheme.Tertiary:
                return `
                    background-color: transparent;
                    color: var(--secondary);
                    padding: var(--size-08);
                    text-decoration: underline;
                    text-decoration-color: var(--primary);
                    text-underline-position: under;

                    &:hover {
                        color: var(--primary);
                        cursor: pointer;
                    }
                `;    
            case ButtonTheme.PrimaryFirst:
            default:    
                return `
                    background-color: var(--primary);
                    color: var(--secondary);

                    &:hover {
                        background-color: var(--secondary);
                        color: var(--primary);
                        cursor: pointer;
                    }
                `;    
        }
    }}
`;

StyledButton.defaultProps = {
    theme: 0
}