import React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Footer } from "../components/shared/Footer";
import { Header } from "../components/shared/Header";
import { IState } from "../shared/interfaces/state.interface";
import {
    selectContractFeeMonthly,
    selectContractFeeSms,
    selectContractInclusiveSms
} from "../shared/redux/app.selectors";

const Wrapper = styled.div`
    margin-bottom: 48px;

    &:last-of-type {
        margin-bottom: 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
`;

const CtaWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Cta = styled(Link)`
    text-decoration: none;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.5;
    padding: 16px 48px;
    background-color: var(--primary);
    color: var(--secondary);

    &:hover {
        background-color: var(--secondary);
        color: var(--primary);
    }
`;

interface IConditionsProps {
    monthly: string;
    feeSms: string;
    inclusiveSms: number;
}

export const Conditions = (props: IConditionsProps) => {
    const { monthly, feeSms, inclusiveSms } = props;

    return (
        <>
            <Header />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Wrapper>
                            <h1>Konditionen für Zurich Agenturen</h1>
                            <p className="highlight">Stand: 23.11.2020</p>
                            <p>
                                Als Agentur der Zurich Gruppe Deutschland erhalten Sie (nachfolgend
                                auch: „Nutzer“) den Tarif Smart mit Sonderkonditionen (Tarif Smart
                                Zurich Service). Diese Sonderkonditionen können Ihnen angeboten
                                werden, weil die Zurich Gruppe Deutschland einen Service-Vertrag mit
                                uns geschlossen hat, von dem die Zurich Exklusiv Partner Agenturen
                                profitieren. Die Sonderkonditionen des Tarifs Smart Zurich Service
                                gelten für Sie daher nur, solange der Service-Vertrag besteht.
                                Danach gilt für Sie der reguläre Tarif Smart.
                            </p>
                        </Wrapper>
                        <Wrapper>
                            <h2>Tarif Smart Zurich Service</h2>
                            <p>
                                In diesem Tarif steht dem Nutzer eine E-Mail-Check-Flatrate, also
                                ein unbegrenzter E-Mail-Versand von manuell initiierten
                                „Flixcheck“-Links zur Verfügung.
                            </p>
                            <p>
                                Zudem sind {inclusiveSms} SMS / Monat zum Versand von
                                „Flixcheck“-Links über das „Flixcheck“-System inklusive.
                            </p>
                            <p>
                                Für jede weitere SMS über das „Flixcheck“-System werden dem Nutzer{" "}
                                {feeSms} / SMS berechnet.
                            </p>
                            <p>
                                Die Kosten für jede weitere SMS werden dem Nutzer nur in Rechnung
                                gestellt, wenn und soweit das gemeinsame Monatskontingent aller
                                Agenturen der ZURICH in dem jeweiligen Monat überschritten worden
                                ist. Das gemeinsame Monatskontingent berechnet sich nach der Formel
                                (angemeldete Agenturen) x {inclusiveSms}. Dieses Kontingent gilt für
                                alle Agenturen einheitlich.
                            </p>
                            <p>
                                Nicht genutzte monatliche Inklusiv-SMS verfallen zum
                                Vertragsmonatsende und können nicht in den Folgemonat übertragen
                                werden.
                            </p>
                            <p>
                                Die Abrechnung erfolgt jeweils mit Ablauf des letzten Tages des
                                jeweiligen Vertragsmonates.
                            </p>
                            <p>
                                Die Mindestlaufzeit beträgt 12 Monate und beginnt mit dem
                                durchgeführten Wechsel in diesen E-Mail-Check-Flatrate-Tarif, also
                                mit Annahme des Vertragsangebotes durch den Anbieter. Die
                                Kündigungsfrist beträgt drei Monate zum Ende der jeweiligen
                                Vertragslaufzeit. Soweit nicht ausdrücklich etwas Abweichendes
                                vereinbart ist, verlängert sich der geschlossene Vertrag automatisch
                                um jeweils ein weiteres Jahr, sofern er nicht rechtzeitig zum Ablauf
                                der Mindestlaufzeit oder der jeweils verlängerten Vertragslaufzeit
                                gekündigt wird.
                            </p>
                            <p>Grundpreis: {monthly}* pro Monat</p>
                        </Wrapper>
                        <Wrapper>
                            <h2>Tarif Smart</h2>
                            <p>
                                In diesem Tarif steht dem Nutzer eine E-Mail-Check-Flatrate, also
                                ein unbegrenzter E-Mail-Versand von manuell initiierten
                                „Flixcheck“-Links zur Verfügung.
                            </p>
                            <p>
                                Zudem sind 100 SMS / Monat zum Versand von „Flixcheck“-Links über
                                das „Flixcheck“-System inklusive.
                            </p>
                            <p>
                                Für jede weitere SMS über das „Flixcheck“-System werden dem Nutzer
                                0,15 Euro / SMS berechnet.
                            </p>
                            <p>
                                Nicht genutzte monatliche Inklusiv-SMS verfallen zum
                                Vertragsmonatsende und können nicht in den Folgemonat übertragen
                                werden.
                            </p>
                            <p>
                                Die Abrechnung erfolgt jeweils mit Ablauf des letzten Tages des
                                jeweiligen Vertragsmonates.
                            </p>
                            <p>
                                Die Mindestlaufzeit beträgt 12 Monate und beginnt mit dem
                                durchgeführten Wechsel in diesen E-Mail-Check-Flatrate-Tarif, also
                                mit Annahme des Vertragsangebotes durch den Anbieter. Die
                                Kündigungsfrist beträgt drei Monate zum Ende der jeweiligen
                                Vertragslaufzeit. Soweit nicht ausdrücklich etwas Abweichendes
                                vereinbart ist, verlängert sich der geschlossene Vertrag automatisch
                                um jeweils ein weiteres Jahr, sofern er nicht rechtzeitig zum Ablauf
                                der Mindestlaufzeit oder der jeweils verlängerten Vertragslaufzeit
                                gekündigt wird.
                            </p>
                            <p>Grundpreis: 19,00 Euro* pro Monat</p>
                            <p>
                                <small>
                                    * Alle Preise sind netto, also zzgl. gesetzlicher Mwst.
                                </small>
                            </p>
                        </Wrapper>
                        <Wrapper>
                            <h2>Zahlungsarten</h2>
                            <p>
                                Die Zahlung ist ausschließlich per SEPA-Lastschriftverfahren
                                möglich.
                            </p>
                        </Wrapper>
                        <CtaWrapper>
                            <Cta to="/registration">Jetzt registrieren</Cta>
                        </CtaWrapper>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        monthly: selectContractFeeMonthly(state),
        feeSms: selectContractFeeSms(state),
        inclusiveSms: selectContractInclusiveSms(state)
    };
};

export const ConditionsWithStore = connect(mapStateToProps, {})(Conditions);
