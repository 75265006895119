import React from "react";
import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Footer = styled.footer`
    height: 52px;
    width: 100%;
    background-color: var(--secondary);
`;

const FooterNavigaiton = styled.ul`
    display: flex;
    padding: 19px 16px;
    font-size: 10px;

    li {
        a {
            color: var(--primary);
            text-decoration: none;
        }

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;

export const StickyFooter = () => {
    return (
        <Footer>
            <Container fluid>
                <Row>
                    <FooterNavigaiton>
                        <li>
                            <a href='https://www.flixcheck.de/impressum/' target='_blank' rel="noreferrer">
                                Impressum
                            </a>
                        </li>
                        <li>
                            <a href='https://www.flixcheck.de/agb/' target='_blank' rel="noreferrer">
                                AGB
                            </a>
                        </li>
                        <li>
                            <a href='https://www.flixcheck.de/datenschutz/' target='_blank' rel="noreferrer">
                                Datenschutz
                            </a>
                        </li>
                    </FooterNavigaiton>
                </Row>
            </Container> 
        </Footer>
    );
}