import React from "react";
import styled from 'styled-components';
import Logo from "../../../assets/images/logo.svg";

const StyledHeader = styled.header`
    padding: 16px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: ${props => props.theme.withMargin ? '64px' : ''};

    img {
        width: 140px;
        height: 70px;
    }
`;

StyledHeader.defaultProps = {
    theme: {
        withMargin: true
    }
}

interface IHeaderProps {
    withMargin?: boolean;
}

export const Header = (props: IHeaderProps) => {
    const {
        withMargin = true
    } = props;

    return (
        <StyledHeader theme={{ withMargin }}>
            <img src={Logo} alt="Flixcheck GmbH"/>
        </StyledHeader>
    )
}