import React, { useEffect, useReducer } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { verify } from "../../shared/redux/app.api-middleware";
import { Button, ButtonTheme, ButtonType } from "../shared/Button";
import { Fieldset, Form, FormElement, PasswordInput } from "../shared/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VerificationValidationSchema } from "../../shared/validation-schema/verification.validation-schema";
import styled from "styled-components";
import { disableSubmitAction, enabledSubmitAction, submitReducer } from "./reducer/submit.reducer";
import { IState } from "../../shared/interfaces/state.interface";
import { selectVerificationCode } from "../../shared/redux/app.selectors";

const SubmitWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;
interface IVerificationFormData {
    verificationCode: string;
}

interface IVerificationFormProps {
    verificationCode: string;
    verify: (code: string) => Promise<void>;
}

export const VerificationForm = (props: IVerificationFormProps) => {
    const { verificationCode, verify } = props;
    const methods = useForm<IVerificationFormData>({
        mode: "all",
        defaultValues: {
            verificationCode: ""
        },
        resolver: yupResolver(VerificationValidationSchema)
    });
    const [state, dispatch] = useReducer(submitReducer, { enabled: false });
    const { enabled } = state;

    const { control, formState, errors } = methods;
    const { isValid } = formState;

    useEffect(() => {
        if (isValid) {
            dispatch(enabledSubmitAction());
        } else {
            dispatch(disableSubmitAction());
        }
    }, [isValid]);

    useEffect(() => {
        if (!!verificationCode) {
            dispatch(enabledSubmitAction());
        }
    }, [verificationCode]);

    const onSubmit = async (data: IVerificationFormData) => {
        dispatch(disableSubmitAction());

        await verify(data.verificationCode);
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Fieldset>
                    <Fieldset.Content>
                        <FormElement label="Verifizierungscode" error={errors.verificationCode}>
                            <Controller
                                control={control}
                                name="verificationCode"
                                as={PasswordInput}
                            />
                        </FormElement>
                        <SubmitWrapper>
                            <Button
                                label="Bestätigen"
                                disabled={!enabled}
                                theme={ButtonTheme.PrimaryFirst}
                                type={ButtonType.Submit}
                            />
                        </SubmitWrapper>
                    </Fieldset.Content>
                </Fieldset>
            </Form>
        </FormProvider>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        verificationCode: selectVerificationCode(state)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        verify: (code: string) => dispatch(verify(code))
    };
};

export const VerificationFormWithStore = connect(
    mapStateToProps,
    mapDispatchToProps
)(VerificationForm);
