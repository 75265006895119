import React from "react";
import { StyledButton } from "./StyledButton";

export enum ButtonType {
    Button = "button",
    Submit = "submit"
}

export enum ButtonTheme {
    PrimaryFirst,
    PrimarySecond,
    SecondaryFirst,
    SecondarySecond,
    Tertiary
}

interface IButtonProps {
    label: string;
    type?: ButtonType;
    theme?: ButtonTheme;
    disabled?: boolean;
    onClick?: () => void;
    className?: string;
}

export const Button = (props: IButtonProps) => {
    const { label, type, theme, disabled, onClick, className } = props;

    return (
        <StyledButton
            theme={theme}
            type={!!type ? type : ButtonType.Button}
            disabled={disabled}
            onClick={() => onClick?.()}
            className={className}
        >
            {label}
        </StyledButton>
    );
};
