import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Button, ButtonTheme, ButtonType } from "../shared/Button";
import { EmailInput, Fieldset, Form, FormElement } from "../shared/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { CodeRequestValidationSchema } from "../../shared/validation-schema/code-request.validation-schema";
import * as httpService from "../../shared/services/http.service";
import { ApiEndpoint } from "../../shared/enums/api-endpoint.enum";
import { handleError } from "../../shared/redux/app.api-middleware";
import { toast } from "react-toastify";

const SubmitWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

const EmailFormElement = styled(FormElement)`
    label {
        font-size: 16px;
    }
`;
interface ICodeRequestFormData {
    email: string;
}

interface ICodeRequestFormProps {
    onRequested?: () => void;
}

export const CodeRequestForm = (props: ICodeRequestFormProps) => {
    const { onRequested } = props;
    const methods = useForm<ICodeRequestFormData>({
        mode: "onChange",
        defaultValues: {
            email: ""
        },
        resolver: yupResolver(CodeRequestValidationSchema)
    });

    const { control, formState, errors } = methods;
    const { isValid } = formState;

    const onSubmit = async (data: ICodeRequestFormData) => {
        httpService
            .performPostRequest(ApiEndpoint.Verification, { data })
            .then(() => {
                toast(
                    "Vielen Dank. Sie erhalten in Kürze eine Mail mit Ihrem Verifizierungscode. ",
                    {
                        className: "fc-toast success"
                    }
                );
                onRequested?.();
            })
            .catch((error) => handleError(error.response));
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Fieldset>
                    <Fieldset.Content>
                        <EmailFormElement label="Ihre E-Mail Adresse" error={errors.email}>
                            <Controller control={control} name="email" as={EmailInput} />
                        </EmailFormElement>
                        <SubmitWrapper>
                            <Button
                                label="Jetzt Verifizierungscode anfordern"
                                disabled={!isValid}
                                theme={ButtonTheme.PrimaryFirst}
                                type={ButtonType.Submit}
                            />
                        </SubmitWrapper>
                    </Fieldset.Content>
                </Fieldset>
            </Form>
        </FormProvider>
    );
};
