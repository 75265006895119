import React from "react";
import styled from "styled-components";
import { useEscapeKey } from "../../../hooks/use-escape-key";
import { Color } from "../../../shared/enums/color.enum";
import { Overlay, OverlayBackground } from "../Overlay";
import { ReactComponent as CancelIcon } from "../../../assets/images/icons/cancel.svg";

const OverlayWithCentering = styled(Overlay)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 600px;
    height: auto;
    background-color: ${Color.White};

    .title-container {
        position: relative;
        padding: 16px;
        text-align: center;
        font-weight: bold;
    }

    .inner-container {
        max-height: 600px;
        overflow: auto;
        padding: 16px;
        -webkit-overflow-scrolling: touch;
    }
`;

const CloseBtn = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    cursor: pointer;
    width: 15px;
    height: 15px;
    border: none;

    img {
        width: 10px;
        height: auto;
    }

    &:focus {
        outline: none;
    }
`;

interface IModalProps {
    title?: string;
    show?: boolean;
    children?: JSX.Element;
    onClose?: () => void;
}

export const Modal = React.forwardRef((props: IModalProps, ref: any) => {
    const { onClose, title, show, children } = props;

    useEscapeKey(() => {
        if (show) {
            onClose?.();
        }
    });

    return show ? (
        <OverlayWithCentering backgroundColor={OverlayBackground.Dark}>
            <ModalContainer ref={ref}>
                {!!title?.length ? <div className="title-container">{title}</div> : null}
                <div className="inner-container">{children}</div>
                <CloseBtn type="button" onClick={() => onClose?.()}>
                    <CancelIcon />
                </CloseBtn>
            </ModalContainer>
        </OverlayWithCentering>
    ) : null;
});
