import React from "react";
import { StyledInput } from './StyledInput';

export const EmailInput = React.forwardRef(
    (props, ref: any) => {
    
    return (
        <StyledInput 
            ref={ref} 
            type='email' 
            {...props} />
    )
})