import { IAction } from "../interfaces/action.interface";
import { IState } from "../interfaces/state.interface";
import { AppActionTypes } from "./app.action-types";

const initialState: IState = {
    verificationCode: undefined,
    contractData: undefined,
    successFulRegistration: undefined,
    registrationData: undefined,
    isUsedCode: undefined,
    isLoading: undefined,
    referral: undefined
};

export function appReducer(state: IState = initialState, action: IAction): IState {
    switch (action.type) {
        case AppActionTypes.SetVerificationCode:
            return {
                ...state,
                verificationCode: action.payload
            };
        case AppActionTypes.SetContractData:
            return {
                ...state,
                contractData: action.payload
            };
        case AppActionTypes.SetLoadingStatus:
            return {
                ...state,
                isLoading: action.payload
            };
        case AppActionTypes.SetSuccessFulRegistration:
            return {
                ...state,
                successFulRegistration: action.payload.successFulRegistration,
                registrationData: action.payload.registrationData
            };
        case AppActionTypes.SetIsUsedCode:
            return {
                ...state,
                isUsedCode: action.payload
            };
        case AppActionTypes.SetReferral:
            return {
                ...state,
                referral: action.payload
            };
        default:
            return state;
    }
}
