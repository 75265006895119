import React from "react";
import styled from "styled-components";

export enum OverlayBackground {
    Light,
    Dark
}

interface IOverlayProps {
    children: JSX.Element | JSX.Element[];
    className?: string;
    backgroundColor?: OverlayBackground;
}

const OverlayDomEl = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${(props) =>
        props.theme.backgroundColor === OverlayBackground.Dark
            ? "rgba(0,0,0 , 0.35)"
            : "rgba(255, 255, 255, 0.7)"};
`;

OverlayDomEl.defaultProps = {
    theme: {
        backgroundColor: OverlayBackground.Dark
    }
};

export const Overlay = (props: IOverlayProps) => {
    const { children, className, backgroundColor } = props;

    return (
        <OverlayDomEl className={className} theme={{ backgroundColor }}>
            {children}
        </OverlayDomEl>
    );
};
