import { IAction } from "../interfaces/action.interface";
import { IRegistrationData } from "../interfaces/registration-data.interface";
import { AppActionTypes } from "./app.action-types";

export function setLoadingStatusAction(status: boolean): IAction {
    return {
        type: AppActionTypes.SetLoadingStatus,
        payload: status
    };
}

export function setVerificationCodeAction(code: string): IAction {
    return {
        type: AppActionTypes.SetVerificationCode,
        payload: code
    };
}

export function setContractData(contractData: any): IAction {
    return {
        type: AppActionTypes.SetContractData,
        payload: contractData
    };
}

export function setSuccessFulRegistration(
    success: boolean,
    registrationData: IRegistrationData
): IAction {
    return {
        type: AppActionTypes.SetSuccessFulRegistration,
        payload: {
            successFulRegistration: success,
            registrationData
        }
    };
}

export function setIsUsedCodeAction(isUsedCode: boolean): IAction {
    return {
        type: AppActionTypes.SetIsUsedCode,
        payload: isUsedCode
    };
}

export function setReferralAction(referral: string): IAction {
    return {
        type: AppActionTypes.SetReferral,
        payload: referral
    };
}
