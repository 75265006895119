import { useEffect } from "react";
import { KeyCode } from "../shared/enums/key-code.enum";

export function useEscapeKey(callback: (...props: any) => void) {
    useEffect(() => {
        const onEscapeKeyDown = ({ keyCode }: KeyboardEvent): void => {
            if (keyCode === KeyCode.Escape) {
                callback?.();
            }
        };

        window.addEventListener("keydown", onEscapeKeyDown);

        return () => {
            window.removeEventListener("keydown", onEscapeKeyDown);
        };
    }, [callback]);
}
