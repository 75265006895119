import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { StyledInput } from './StyledInput';
import ViewIcon from '../../../assets/images/icons/show-password.svg';
import CheckIcon from '../../../assets/images/icons/check.svg';
import CancelIcon from '../../../assets/images/icons/cancel.svg';
import * as validationService from "../../../shared/services/validation.service";

enum InputType {
    Password = 'password',
    Text = 'text'
}

const InputWrapper = styled.div`
    position: relative;
`;

const ToggleBtn = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: var(--size-04);
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 24px;
    height: 24px;

    img {
        width: 24px;
        height: auto;
    }

    &:focus {
        outline: none;
    }
`;

const PasswordLegend = styled.ul`
    display: flex;
    align-items: center;
    list-style-type: none;
    font-size: 12px;
    padding-left: 0;
    margin: 8px 0;

    li {
        display: flex;
        align-items: center;
        color: var(--red);
        font-weight: bold;

        &.isValid {
            color: var(--primary);
        }

        img {
            width: 10px;
            height: auto;
            margin-right: 8px;
        }

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;

export const PasswordInput = React.forwardRef(
    ({ value, withError, ...rest }: any, ref: any) => {
    const [ focused, setFocused ] = useState(false);
    const [ isValid, setIsValid ] = useState({ 
        lowercase: false, 
        uppercase: false, 
        specialChars: false,
        length: false
    });
    const [ type, setType ] = useState(InputType.Password);

    const toggleInputType = () => {
        if (type === InputType.Password) {
            setType(InputType.Text);
        } else {
            setType(InputType.Password);
        }
    }

    useEffect(() => {
        if (withError) {
            setIsValid({
                lowercase: validationService.hasLowerCaseCharacters(value),
                uppercase: validationService.hasUpperCaseCharacters(value),
                specialChars: validationService.hasSpecialCharacters(value),
                length: value.length >= 8
            });
        }
    }, [withError, value])

    return (
        <>
            <InputWrapper>
                <StyledInput 
                    {...rest}
                    value={value}
                    ref={ref} 
                    type={type} 
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}/>
                <ToggleBtn 
                    type='button'
                    onClick={toggleInputType}>
                    <img src={ViewIcon} alt=""/>
                </ToggleBtn>    
            </InputWrapper>
            {withError && focused && (
                <PasswordLegend>
                    <li className={`${isValid.length ? 'isValid' : ''}`}>
                        <img src={isValid.length ? CheckIcon : CancelIcon} alt=""/> mind. 8 Zeichen
                    </li>
                    <li className={`${isValid.lowercase ? 'isValid' : ''}`}>
                        <img src={isValid.lowercase ? CheckIcon : CancelIcon} alt=""/> Kleinbuchstaben
                    </li>
                    <li className={`${isValid.uppercase ? 'isValid' : ''}`}>
                        <img src={isValid.uppercase ? CheckIcon : CancelIcon} alt=""/> Großbuchstaben
                    </li>
                    <li className={`${isValid.specialChars ? 'isValid' : ''}`}>
                        <img src={isValid.specialChars ? CheckIcon : CancelIcon} alt=""/> Sonderzeichen
                    </li>
                </PasswordLegend>
            )}
        </>
    )
})