import axios from "axios";

const baseURL =
    process.env.REACT_APP_BASE_URL || "https://api.register.zurich.adapter.flixcheck.com/";

if (baseURL.substr(-1) !== "/") {
    throw new Error("baseURL must end with a slash");
}

export const ApiConfig = axios.create({
    baseURL,
    responseType: "json"
});
