import React, { useEffect, useReducer } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { IRegistrationData } from "../../shared/interfaces/registration-data.interface";
import { IState } from "../../shared/interfaces/state.interface";
import { register } from "../../shared/redux/app.api-middleware";
import {
    selectIsUsedCode,
    selectReferral,
    selectSuccessFulRegistration,
    selectVerificationCode
} from "../../shared/redux/app.selectors";
import { Button, ButtonTheme, ButtonType } from "../shared/Button";
import {
    CheckboxInput,
    EmailInput,
    Fieldset,
    Form,
    FormElement,
    PasswordInput,
    TextInput
} from "../shared/Form";
import { RadioInput } from "../shared/Form/RadioInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegistrationValidationSchema } from "../../shared/validation-schema/registration.validation-schema";
import SettingsIcon from "../../assets/images/icons/settings.svg";
import CompanyIcon from "../../assets/images/icons/company.svg";
import CreditCardIcon from "../../assets/images/icons/credit-card.svg";
import UserIcon from "../../assets/images/icons/user.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { disableSubmitAction, enabledSubmitAction, submitReducer } from "./reducer/submit.reducer";

const StyledH4 = styled.h4`
    display: flex;
    align-items: center;
    flex-flow: wrap;

    img {
        margin-right: 10px;
        width: 32px;
        height: auto;
    }
`;

const SubmitWrapper = styled.div`
    margin-top: 32px;

    button {
        margin-top: 16px;
    }
`;

const FORM_DEFAULT_VALUES: IRegistrationData = {
    acceptedTermsAndPrivacyPolicy: false,
    email: "",
    password: "",
    company: {
        name: "",
        street: "",
        postcode: "",
        city: "",
        phone: ""
    },
    contactPerson: {
        gender: "",
        firstname: "",
        lastname: ""
    },
    billing: {
        accountOwner: "",
        iban: "",
        bic: ""
    }
};

interface IRegistrationFormProps {
    verificationCode: string;
    isUsedCode: boolean;
    referral: string;
    successFullRegistration: boolean | undefined;
    register: (data: IRegistrationData, code: string, referral: string) => Promise<void>;
}

export const RegistrationForm = (props: IRegistrationFormProps) => {
    const { verificationCode, isUsedCode, referral, successFullRegistration, register } = props;
    const methods = useForm<IRegistrationData>({
        mode: "all",
        defaultValues: FORM_DEFAULT_VALUES,
        resolver: yupResolver(RegistrationValidationSchema)
    });
    const [state, dispatch] = useReducer(submitReducer, { enabled: false });
    const { enabled } = state;

    const { reset, control, formState, register: formRegister, errors, getValues } = methods;
    const { isValid, submitCount } = formState;

    useEffect(() => {
        if (isUsedCode) {
            dispatch(disableSubmitAction());
            return;
        }

        if (isValid) {
            dispatch(enabledSubmitAction());
        } else {
            dispatch(disableSubmitAction());
        }
    }, [isUsedCode, isValid]);

    useEffect(() => {
        if (
            submitCount > 0 &&
            typeof successFullRegistration === "boolean" &&
            !successFullRegistration
        ) {
            reset(getValues());
        }
    }, [reset, getValues, successFullRegistration, submitCount]);

    const onSubmit = async (data: IRegistrationData) => {
        if (!verificationCode || isUsedCode) {
            return;
        }

        dispatch(disableSubmitAction());

        await register(data, verificationCode, referral);
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Fieldset>
                    <Fieldset.Legend>
                        <StyledH4>
                            <img src={SettingsIcon} alt="Logindaten" />
                            Logindaten
                        </StyledH4>
                    </Fieldset.Legend>
                    <Fieldset.Content>
                        <FormElement label="E-Mail Adresse" required={true} error={errors.email}>
                            <Controller control={control} name="email" as={EmailInput} />
                        </FormElement>
                        <FormElement label="Passwort" required={true}>
                            <Controller
                                control={control}
                                name="password"
                                as={<PasswordInput withError={true} />}
                            />
                        </FormElement>
                    </Fieldset.Content>
                </Fieldset>
                <Fieldset>
                    <Fieldset.Legend>
                        <StyledH4>
                            <img src={CompanyIcon} alt="Firmendaten" />
                            Firmendaten
                        </StyledH4>
                    </Fieldset.Legend>
                    <Fieldset.Content>
                        <FormElement
                            label="Firmenname"
                            required={true}
                            error={errors.company?.name}
                        >
                            <Controller control={control} name="company.name" as={TextInput} />
                        </FormElement>
                        <FormElement label="Straße" required={true} error={errors.company?.street}>
                            <Controller control={control} name="company.street" as={TextInput} />
                        </FormElement>
                        <FormElement
                            label="Postleitzahl"
                            required={true}
                            error={errors.company?.postcode}
                        >
                            <Controller control={control} name="company.postcode" as={TextInput} />
                        </FormElement>
                        <FormElement label="Stadt" required={true} error={errors.company?.city}>
                            <Controller control={control} name="company.city" as={TextInput} />
                        </FormElement>
                        <FormElement
                            label="Telefonnummer"
                            required={true}
                            error={errors.company?.phone}
                        >
                            <Controller control={control} name="company.phone" as={TextInput} />
                        </FormElement>
                    </Fieldset.Content>
                </Fieldset>
                <Fieldset>
                    <Fieldset.Legend>
                        <StyledH4>
                            <img src={UserIcon} alt="Kontaktperson" />
                            Kontaktperson
                        </StyledH4>
                    </Fieldset.Legend>
                    <Fieldset.Content>
                        <FormElement
                            label="Anrede"
                            required={true}
                            isRadioElement={true}
                            error={errors.contactPerson?.gender}
                        >
                            <RadioInput
                                name="contactPerson.gender"
                                id="female"
                                value="female"
                                label="Frau"
                                ref={formRegister}
                            />
                            <RadioInput
                                name="contactPerson.gender"
                                id="male"
                                value="male"
                                label="Herr"
                                ref={formRegister}
                            />
                        </FormElement>
                        <FormElement
                            label="Vorname"
                            required={true}
                            error={errors.contactPerson?.firstname}
                        >
                            <Controller
                                control={control}
                                name="contactPerson.firstname"
                                as={TextInput}
                            />
                        </FormElement>
                        <FormElement
                            label="Nachname"
                            required={true}
                            error={errors.contactPerson?.lastname}
                        >
                            <Controller
                                control={control}
                                name="contactPerson.lastname"
                                as={TextInput}
                            />
                        </FormElement>
                    </Fieldset.Content>
                </Fieldset>
                <Fieldset>
                    <Fieldset.Legend>
                        <StyledH4>
                            <img src={CreditCardIcon} alt="Kontodaten" />
                            Kontodaten
                        </StyledH4>
                    </Fieldset.Legend>
                    <Fieldset.Content>
                        <FormElement
                            label="Kontoinhaber"
                            required={true}
                            error={errors.billing?.accountOwner}
                        >
                            <Controller
                                control={control}
                                name="billing.accountOwner"
                                as={TextInput}
                            />
                        </FormElement>
                        <FormElement label="IBAN" required={true} error={errors.billing?.iban}>
                            <Controller control={control} name="billing.iban" as={TextInput} />
                        </FormElement>
                        <FormElement label="BIC" error={errors.billing?.bic}>
                            <Controller control={control} name="billing.bic" as={TextInput} />
                        </FormElement>
                    </Fieldset.Content>
                </Fieldset>
                <SubmitWrapper>
                    <FormElement required={true} error={errors.acceptedTermsAndPrivacyPolicy}>
                        <Controller
                            control={control}
                            name="acceptedTermsAndPrivacyPolicy"
                            id="acceptedTermsAndPrivacyPolicy"
                            as={
                                <CheckboxInput>
                                    <>
                                        Ich akzeptiere die{" "}
                                        <a
                                            href="https://www.flixcheck.de/agb/"
                                            title="Allgemeine Geschäftsbedingungen ansehen"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            AGB
                                        </a>
                                        ,{" "}
                                        <a
                                            href="https://www.flixcheck.de/datenschutz/"
                                            title="Datenschutzerklärung ansehen"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Datenschutzerklärung
                                        </a>{" "}
                                        und die <Link to="/conditions">Sonderkonditionen</Link> für
                                        Zurich Agenturen.
                                    </>
                                </CheckboxInput>
                            }
                        />
                    </FormElement>
                    <Button
                        label="Registrieren"
                        disabled={!enabled}
                        theme={ButtonTheme.PrimaryFirst}
                        type={ButtonType.Submit}
                    />
                </SubmitWrapper>
            </Form>
        </FormProvider>
    );
};

const mapStateToProps = (state: IState) => {
    return {
        verificationCode: selectVerificationCode(state),
        isUsedCode: selectIsUsedCode(state),
        referral: selectReferral(state),
        successFullRegistration: selectSuccessFulRegistration(state)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        register: (data: IRegistrationData, code: string, referral: string) =>
            dispatch(register(data, code, referral))
    };
};

export const RegistrationFormWithStore = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrationForm);
