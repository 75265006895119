import { string, object, boolean } from "yup";
import * as validationService from "../services/validation.service";

export const RegistrationValidationSchema = object({
    acceptedTermsAndPrivacyPolicy: boolean().oneOf(
        [true],
        "Sie müssen die AGB, Datenschutzerklärung und Sonderkonditionen akzeptieren."
    ),
    email: string().required("Bitte geben Sie eine E-Mail Adresse an."),
    password: string()
        .required("Bitte geben Sie ein Passwort an.")
        .min(8, "Das Passwort muss mindestens 8 Zeichen beinhalten.")
        .test("lowercase", "Das Passwort muss kleine Buchstaben enthalten.", (value) =>
            validationService.hasLowerCaseCharacters(value)
        )
        .test("uppercase", "Das Passwort muss große Buchstaben enthalten.", (value) =>
            validationService.hasUpperCaseCharacters(value)
        )
        .test("numbers", "Das Passwort muss Sonderzeichen enthalten.", (value) =>
            validationService.hasSpecialCharacters(value)
        ),
    company: object({
        name: string().required("Bitte geben Sie einen Firmennamen an."),
        street: string().required("Bitte geben Sie eine Straße an."),
        postcode: string().required("Bitte geben Sie eine Postleitzahl an."),
        city: string().required("Bitte geben Sie eine Stadt an."),
        phone: string()
            .test("phone", "Sie dürfen ausschließlich Zahlen und Leerzeichen nutzen.", (value) =>
                validationService.validatePhoneNumber(value)
            )
            .required("Bitte geben Sie eine gültige Telefonnummer an.")
    }),
    contactPerson: object({
        gender: string().required("Bitte wählen Sie eine Anrede."),
        firstname: string().required("Bitte geben Sie einen Vornamen an."),
        lastname: string().required("Bitte geben Sie einen Nachnamen an.")
    }),
    billing: object({
        accountOwner: string().required("Bitte geben Sie einen Kontoinhaber an."),
        iban: string().required("Bitte geben Sie eine gültige IBAN an.")
    })
});
